import React from 'react';
import Image from 'next/image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok } from '@fortawesome/free-brands-svg-icons';
import Link from 'next/link';

const WAButtonSmall = ({ phone }) => (
    <div className="flex">
        <button className="w-fit h-auto rounded-full bg-green-button_whatsapp md:hover:bg-green-button_whatsapp-h font-fredoka text-white text-2xl  px-3 py-2  inline-block">
            <div className="flex justify-evenly flex-row items-center gap-2">
                <span className="">
                    <div className='relative w-[24px] h-[24px]'>
                        <Image src='https://cdn.fixat.mx/assets/img/no-webp/whatsapp.svg'
                            alt="whatsApp logo"
                            title="Contáctanos por WhatsApp"
                            layout='fill'
                            objectFit='contain'
                        />
                    </div>
                </span>
                <div className='flex flex-col font-quicksand'>
                    <p className="m-0 flex text-left font-bold text-[10px]">Escríbenos al WhatsApp: </p>
                    <p className="m-0 flex text-left font-semibold text-base underline underline-offset-1">{phone}</p>
                </div>
            </div>
        </button >
    </div >
)
const Footer = () => {
    return (
        <footer className="w-full relative text-center xs:bg-green_footer md:bg-transparent">
            <div className="xs:hidden md:block">
                <Image
                    src="https://cdn.fixat.mx/assets/bg/no-webp/pasto2x.png"
                    alt="Fondo del pie de página"
                    objectFit="cover"
                    className="bg-blue-stress"
                    layout="fill"
                    quality={100}
                    loading="lazy"
                />
            </div>
            <div className="xs:block md:hidden"></div>
            <div className="max-w-screen-xl mx-auto relative xs:mt-6 md:mt-20 pt-3 ">
                <div className='flex flex-row justify-between mmd:flex-col mmd:items-center ssm:flex-col w-full '>
                    <div className="md:w-5/12 w-full">
                        <nav className="w-full flex md:flex-row gap-4 md:gap-0 flex-col md:items-start items-center justify-center">
                            <span className="w-3/5 text-center">
                                <div className="xs:mt-0 ssm:mt-0 flex flex-col items-center">
                                    <div className='relative w-full h-[65px]'>
                                        <Image
                                            src='https://cdn.fixat.mx/assets/img/no-webp/telefono-fixat.png'
                                            alt="Teléfono Fixat"
                                            title="Teléfono"
                                            layout='fill'
                                            objectFit='contain'
                                            loading='lazy'
                                        />
                                    </div>
                                    <div className='flex flex-col pt-1 gap-1 text-white'>
                                        <span className='text-xs '>¿Dudas?</span>
                                        <a href='https://wa.me/525565653439?text=%C2%A1Hola%20Fixat%21%20Necesito%20resolver%20algunas%20dudas%20antes%20de%20hacer%20el%20pago' target="_blank" rel="noopener noreferrer nofollow" >
                                            <WAButtonSmall phone="+52 55 6565 3439" />
                                        </a>
                                        <span className='text-[10px] py-2'>
                                            Llámanos: <a href="tel:5611131391" className='underline' rel='nofollow'>+52 56 1113 1391</a>
                                        </span>
                                        <hr className='bg-white'></hr>
                                        <div className='flex flex-col'>
                                            <span className='text-sm font-semibold'>Horario de atención</span>
                                            <span className='text-sm font-medium'>Lunes a Viernes</span>
                                            <span className='text-sm font-medium'>10:00 a 19:00hrs</span>
                                        </div>
                                    </div>
                                </div>
                            </span>
                            <span className="w-1/3 text-center">
                                <div className=" ssm:mt-0  md:flex md:items-start ">
                                    <a href="mailto:hola@fixat.mx" rel='nofollow'>
                                        <div className='relative w-full h-[67px]'>
                                            <Image
                                                src='https://cdn.fixat.mx/assets/img/no-webp/mail-fixat.png'
                                                alt="Correo electrónico Fixat"
                                                title="Correo electrónico"
                                                layout='fill'
                                                objectFit='contain'
                                                loading='lazy'
                                            />
                                        </div>
                                        <p className='text-xs text-white'>
                                            hola@fixat.mx
                                        </p>
                                    </a>
                                </div>
                            </span>
                            <span className="w-1/3 text-center">
                                <div className=" ssm:mt-0">
                                    <a href="https://goo.gl/maps/Uy9hSnG5GCX5mG3t9" target="_blank" rel="noopener noreferrer nofollow">
                                        <div className='relative w-full h-[67px]'>
                                            <Image
                                                src='https://cdn.fixat.mx/assets/img/no-webp/dirección-fixat.png'
                                                alt="Dirección Fixat"
                                                title="Dirección"
                                                layout='fill'
                                                objectFit='contain'
                                                loading='lazy'
                                            />
                                        </div>
                                        <p className='text-xs text-white'>
                                            Blvd. Adolfo López Mateos 163, Mixcoac, Benito Juárez, 03910 Ciudad de México
                                        </p>
                                    </a>
                                </div>
                            </span>
                        </nav>
                    </div>
                    <div className="md:w-4/12 w-full ssm:mb-4 mmd:mt-6 mmd:mb-6">
                        <div className='flex flex-row ssm:mt-4 justify-center gap-10'>
                            <nav className="">
                                <a href="https://www.facebook.com/Fixat-228162854300046/?fref=ts"
                                    target="_blank" rel="noreferrer nofollow" aria-label='Facebook' alt="facebook" className="inline-block text-2xl text-center border-2 border-solid border-white rounded-full text-white pt-0.5 pr-0.5 ssm:mt-0 w-10 h-10">
                                    <span> </span><FontAwesomeIcon icon={faFacebookF} />
                                </a>
                            </nav>
                            <nav className="">
                                <a href="https://www.instagram.com/fixatoficial"
                                    target="_blank" rel="noreferrer nofollow" aria-label='Instagram' alt="instagram" className="inline-block text-2xl text-center border-2 border-solid border-white rounded-full ssm:mt-0 text-white pt-0.5 w-10 h-10">
                                    <span> </span><FontAwesomeIcon icon={faInstagram} />
                                </a>
                            </nav>
                            <nav className="">
                                <a href="https://www.tiktok.com/@fixatoficial"
                                    target="_blank" rel="noreferrer nofollow" aria-label='Tik-Tok' alt="tiktok" className="inline-block text-2xl text-center border-2 border-solid border-white rounded-full ssm:mt-0 text-white pt-0.5 w-10 h-10 mb-4">
                                    <span> </span><FontAwesomeIcon icon={faTiktok} />
                                </a>
                            </nav>
                        </div>
                        <div className="flex flex-col">
                            <span className="text-sm leading-none text-white mb-2">
                                ¡Síguenos!
                            </span>
                            <a href="https://fixat.mx/blog" target="_blank" rel="noreferrer" className="text-sm text-white underline underline-offset-4 hover:underline">Visita nuestro blog</a>
                        </div>
                    </div>
                    <div className="md:w-4/12 w-full mmd:mb-10">
                        <div className="w-full">
                            <div className="xs:mt-12 md:mt-7">
                                <div className='relative w-full h-[38px]'>
                                    <Image
                                        src='https://cdn.fixat.mx/assets/img/no-webp/metodos-de-pago-inicio.png'
                                        alt="Métodos de Pago: Visa, Mastercard, America Express"
                                        title="Métodos de Pago: Visa, Mastercard, America Express"
                                        layout='fill'
                                        objectFit='contain'
                                        loading='lazy'
                                    />
                                </div>
                            </div>
                        </div>
                        <nav className="w-full">
                            <div className="text-md mt-2">
                                <Link href="/aviso-de-privacidad" rel='follow' alt="/aviso-de-privacidad">
                                    <a rel='follow' className="text-xs pr-6 text-white">
                                        Aviso de Privacidad
                                    </a>
                                </Link>
                                <Link href="/terminos-y-condiciones" rel='follow' alt='/terminos-y-condiciones'>
                                    <a rel='follow' className="text-xs pl-4 text-white">
                                        Términos y Condiciones
                                    </a>
                                </Link>
                            </div>
                        </nav>
                    </div>
                </div>
                <div className="w-full text-center">
                    <div className="xs:mt-8 md:mt-0">
                        <div className="mb-3">
                            <span className="text-sm text-white">
                                Proudly by
                            </span>
                        </div>
                        <div className="w-full">
                            <div className="w-full h-10 relative mb-6">
                                <Image
                                    src='https://cdn.fixat.mx/assets/img/no-webp/logo-kea.png'
                                    alt="Logo de KEA"
                                    title="KEA"
                                    layout="fill"
                                    objectFit="contain"
                                    loading='lazy'
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;